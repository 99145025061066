import swal from 'sweetalert2'
import React, { useEffect, useState, useMemo } from 'react'
import Modal from 'react-modal'
import { Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Barcode from 'react-barcode'
import * as Sentry from '@sentry/browser'
import { STATUSES } from '@iboxen/db-models/constants'
import { useTranslation } from 'react-i18next'
import {
  getBoxTypes,
  updateParcel,
  getSettings,
  triggerSendSMS,
} from '../redux/actions'
import CloseModalButton from './CloseModalButton'
import { statusTransitionServiceTranslation, formatParcelLogs } from '../utils'
import { useSimpleForm } from '../hooks/useSimpleForm'
import {
  getParcel,
  requestCompartment,
  addParcelStatus,
  cacheParcels,
} from '../redux/parcels/slice.parcels'
import { useMe } from '../hooks/useMe'

function BoxOption({
  boxType: {
    dimensions,
    display: {
      friendlyName: { default: name },
    },
  },
  parcel: {
    _id,
    parcelId,
    boxType: {
      display: {
        friendlyName: { default: parcelBoxName },
      },
    },
  },
  available,
}) {
  const { t } = useTranslation('parcels')
  const { t: transResponseMessage } = useTranslation('responseMessages')
  const dispatch = useDispatch()
  const submitBookingRequest = (sizeName) => {
    dispatch(requestCompartment({ parcelId, sizeName })).then(({ payload }) => {
      if (payload?.responseText) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: transResponseMessage(
            `${payload.responseText.replaceAll(' ', '-')}`
          ),
          showConfirmButton: false,
          timer: 1500,
        })
      }
      if (payload?.updatedParcel?.newBox?.parcel) {
        dispatch(getParcel({ id: _id }))
      } else {
        dispatch(getParcel({ id: _id }))
      }
    })
  }

  if (!dimensions) return null
  return (
    <div style={{ margin: 20 }}>
      <div
        style={{
          height: '70%',
          width: '100%',
          border: '1px solid grey',
          padding: 20,
          backgroundColor: 'white',
          borderRadius: 4,
          opacity: available ? 1 : 0.5,
        }}
      >
        <p>
          {t('height')}: {dimensions.height}
        </p>
        <p>
          {t('depth')}: {dimensions.length}
        </p>
        <p>
          {t('width')}: {dimensions.width}
        </p>
      </div>
      <br />
      {parcelBoxName.toLowerCase() !== name.toLowerCase() ? (
        <button
          type="submit"
          className="ibButton"
          onClick={() => submitBookingRequest(name)}
          disabled={!available}
          style={{
            opacity: available ? 1 : 0.5,
            cursor: available ? 'pointer' : 'not-allowed',
          }}
        >
          {t('book')} {t(`boxTypes.${name}`)}
        </button>
      ) : (
        <div style={{ paddingTop: 6 }}>{t('currentBox')}</div>
      )}
    </div>
  )
}

const transformLogString = (log, t) => {
  if (log.type === 'ACTION' && log.subType === 'SMS') {
    return t('trailSmsSent', {
      phone: log.data.phone,
    })
  }
  if (log.type === 'CHANGE') {
    return t('trailValueChanged', {
      field: t(log.subType?.toLowerCase?.()) ?? '',
      oldValue: log.data.previousValue,
      newValue: log.data.newValue,
    })
  }
  if (log.type === 'COMPARTMENT_CHANGE') {
    return t('trailCompartmentChanged', {
      compartment: t(`boxTypes.${log.data.compartment}`),
    })
  }
  return ''
}
export default function HandleParcelModal({ closeModal, parcelId }) {
  const [boxTypes, setBoxTypes] = useState([])
  const { t } = useTranslation('parcels')
  const { t: transCommon } = useTranslation('common')
  const { t: transResponseMessage } = useTranslation('responseMessages')
  const [availableBoxTypes, setAvailableBoxTypes] = useState([])
  const [parcelLogs, setParcelLogs] = useState([])
  const [serviceLogs, setServiceLogs] = useState([])
  const dispatch = useDispatch()
  const parcel = useSelector((state) => state.parcels.cache[parcelId])
  const { user, carrier } = useMe()

  const [edit, setEdit] = useState('')
  const [smsStatus, setSmsStatus] = useState('')

  const consumerForm = useSimpleForm({
    fields:
      parcel?.flow === 'pickup'
        ? {
            fullName: parcel?.sender?.fullName || '',
            phoneNumber: parcel?.sender?.phoneNumber || '',
          }
        : {
            fullName: parcel?.receiver?.fullName || '',
            phoneNumber: parcel?.receiver?.phoneNumber || '',
          },
    async onSubmit(_, updated) {
      const result = await dispatch(
        updateParcel(parcel._id, {
          [parcel?.flow === 'pickup' ? 'sender' : 'receiver']: updated,
        })
      )
      if (result.error) {
        consumerForm.reset()
      } else {
        await dispatch(getParcel({ id: parcelId }))
      }
      setEdit('')
    },
  })
  const [messageSettings, setMessageSettings] = useState(null)

  useEffect(() => {
    dispatch(getSettings()).then((data) => {
      setMessageSettings(data.response.message)
    })
    dispatch(getParcel({ id: parcelId }))
  }, [dispatch, parcelId])

  const sendSms = async () => {
    if (smsStatus) return
    setSmsStatus('pending')
    dispatch(triggerSendSMS(parcel.parcelId))
    setSmsStatus('queued')
  }

  useEffect(() => {
    if (boxTypes.length || !parcel?.location?._id) return

    formatParcelLogs(parcel, dispatch, t).then((logs) => {
      setParcelLogs(logs)
    })
    formatParcelLogs(parcel, dispatch, t, 'service|consumer').then((logs) => {
      setServiceLogs(logs)
    })
    dispatch(getBoxTypes(parcel.location._id)).then((data) => {
      if (data?.error) {
        return
      }
      const available = []
      data.response.boxTypes.forEach((boxType) => {
        if (
          (boxType.active === true ||
            Object.prototype.hasOwnProperty.call(boxType, 'active') ===
              false) &&
          !boxType.parcelId &&
          !boxType.bookingId &&
          !available?.find?.((bt) => {
            return (
              bt?.display.friendlyName.default ===
              boxType?.boxTypeDetails?.display.friendlyName.default
            )
          })
        ) {
          available.push(boxType.boxTypeDetails)
        }
      })
      const filteredBoxTypes = data.response.boxTypes.reduce((acc, boxType) => {
        if (
          !acc?.find?.(
            (bt) =>
              bt?.display.friendlyName.default ===
              boxType?.boxTypeDetails?.display.friendlyName.default
          )
        ) {
          acc.push(boxType.boxTypeDetails)
        }
        return acc
      }, [])
      setBoxTypes(
        filteredBoxTypes.sort((a, b) => {
          return a.display.order - b.display.order
        })
      )
      setAvailableBoxTypes(available)
    })
  }, [availableBoxTypes, boxTypes.length, dispatch, parcel, t])
  const role = STATUSES.VALID_FLOW_STATUS_TRANSITIONS_ROLES.includes(
    `iadmin/${carrier.username}`
  )
    ? `role|iadmin/${carrier.username}`
    : 'role|iadmin'
  const flowStatusModifier =
    STATUSES.VALID_FLOW_STATUS_TRANSITIONS_ROLES.includes(
      `${role.substring(5)}:${window.environment}`
    )
      ? window.environment
      : undefined
  const appendableStatuses = useMemo(
    () =>
      carrier.isAllowedTo('addAnyStatus')
        ? window.statusOptions.appendable[parcel.flow || 'delivery']
        : STATUSES.getStatusTransitionFlow(
            parcel,
            role,
            undefined,
            flowStatusModifier
          )[parcel.currentStatus],
    [carrier, parcel, role, flowStatusModifier]
  )

  const [addStatus, setAddStatus] = useState({ name: appendableStatuses[0] })
  const [errorsIsExpanded, setErrorsIsExpanded] = useState(false)

  const saveAddStatus = (/* status = addStatus */) => {
    // TODO INTE HÄMTA NY LISTA UTAN FIXA RESPONS SÅ DEN GÅR ATT UPPDATERA I REDUX
    // NU SAKNAS location
    dispatch(
      addParcelStatus({
        parcelId: parcel.parcelId,
        _id: parcel._id,
        addStatus,
      })
    ).then(({ payload }) => {
      if (payload?.responseText) {
        swal.fire({
          position: 'center',
          icon: 'success',
          title: transResponseMessage(
            `${payload.responseText.replaceAll(' ', '-')}`
          ),
          showConfirmButton: false,
          timer: 1500,
        })
      }
      if (payload?.updatedParcels) {
        dispatch(cacheParcels({ parcels: payload.updatedParcels }))
      } else {
        dispatch(getParcel({ id: parcelId }))
      }
    })
  }

  const bringExtraCodes = [
    {
      ReasonCode: 'BT',
      ActionCode: 'AU',
      description:
        'BT - Överskriden liggtid. Åtgärd: AU - Väntar på upphämtning',
    },
    {
      ReasonCode: 'CD',
      ActionCode: 'CB',
      description:
        'CD - Omdirigerat. Åtgärd: CB - Överlämnas till bemannat ombud',
    },
  ]

  useEffect(() => {
    if (parcel.location && parcel.isAllocated !== false && !parcel.controller)
      Sentry.captureException(
        new Error(
          `Cannot read property "mark" of undefined for parcel ${parcel.parcelId}`
        )
      )
  }, [parcel])

  const renderParcelDetails = () => {
    console.log(parcel)
    if (!parcel.location) return null

    const parcelLocationString = `${parcel.location.address.street}, ${parcel.location.address.zip} ${parcel.location.address.city}`
    return (
      <div className="flexyContainer">
        <div className="flexyColumn">
          <div style={{ flexGrow: 'initial' }} className="flexyItem">
            <b>{t('created')}: </b>
            <span>{window.niceDate(parcel.created)}</span>
          </div>
          <div style={{ flexGrow: 'initial' }} className="flexyItem">
            <b>{t('location')}: </b>
            <span>{parcelLocationString}</span>
          </div>
          {parcel.controller?.mark ? (
            <div style={{ flexGrow: 'initial' }} className="flexyItem">
              <b>{t('locker')}: </b>
              <span>{parcel.controller.mark}</span>
            </div>
          ) : null}
          {parcel.boxNumber ? (
            <div style={{ flexGrow: 'initial' }} className="flexyItem">
              <b>{t('box')}: </b>
              <span>{parcel.boxNumber}</span>
            </div>
          ) : null}
          <div style={{ flexGrow: 'initial' }} className="flexyItem">
            <b>{t('status')}: </b>
            <span>{t(`statuses.${parcel.currentStatus}`)}</span>
          </div>
          {parcel.currentStatus === 'deviation' &&
            parcel.statuses.at(-1).data?.code && (
              <div style={{ flexGrow: 'initial' }} className="flexyItem">
                <b>{t('deviationCode')}: </b>
                <span>
                  {t(`deviationCodes.${parcel.statuses.at(-1).data?.code}`, {
                    defaultValue: parcel.statuses.at(-1).data?.code,
                  })}
                </span>
              </div>
            )}
          {parcel.ageControl ? (
            <div className="flexyItem">
              <b>{t('ageControl')}: </b>
              <span>{parcel.ageControl}</span>
            </div>
          ) : null}
        </div>
        <div className="flexyColumn">
          <div className="flexyItem">
            <b>{t('consumer')}: </b>
            <span>
              {edit === 'consumer' ? (
                <input {...consumerForm.fields.fullName} />
              ) : (
                consumerForm.fields.fullName.value
              )}
              <br />
              {edit === 'consumer' ? (
                <input {...consumerForm.fields.phoneNumber} />
              ) : (
                consumerForm.fields.phoneNumber.value
              )}
            </span>
          </div>
          <div className="flexyItem">
            {carrier.isAllowedTo('updateParcelConsumerData') &&
              user.isAllowedTo('updateParcelConsumerData') &&
              ['created', 'on-way', 'pickup-on-way', 'in-box'].includes(
                parcel.currentStatus
              ) &&
              edit !== 'consumer' && (
                <button
                  type="button"
                  className="ibButton"
                  onClick={() => setEdit('consumer')}
                >
                  {t('edit')}
                </button>
              )}
          </div>
          {edit === 'consumer' && (
            <div className="flexyItem">
              <button
                type="button"
                className="ibButton"
                onClick={() => consumerForm.submit()}
                disabled={
                  consumerForm.submitting || consumerForm.changed.length === 0
                }
              >
                {consumerForm.submitting
                  ? transCommon('saving')
                  : transCommon('save')}
              </button>
              <button
                type="button"
                className="ibButton ibSmallWarn"
                onClick={() => {
                  consumerForm.reset()
                  setEdit(null)
                }}
                disabled={consumerForm.submitting}
              >
                {t('abort')}
              </button>
            </div>
          )}
          {carrier.isAllowedTo('sendConsumerSMS') &&
            user.isAllowedTo('sendConsumerSMS') && (
              <div
                style={{
                  justifyContent: 'space-evenly',
                  display: 'flex',
                  marginTop: '20px',
                  alignItems: 'flex-start',
                }}
              >
                <button
                  disabled={
                    smsStatus ||
                    !messageSettings?.integrationEvents?.find?.(
                      (event) =>
                        event.triggerCode === parcel.currentStatus &&
                        event.type !== 'email'
                    )
                  }
                  type="button"
                  className="ibButton"
                  style={{ height: '35px' }}
                  onClick={() => sendSms()}
                >
                  {t('sendSms')}
                </button>
                <div style={{ marginLeft: '20px', textAlign: 'left' }}>
                  <p>{t('smsLong')}</p>
                </div>
              </div>
            )}
          {smsStatus === 'pending' && <p>{t('smsSending')}</p>}
          {smsStatus === 'queued' && <p>{t('smsSent')}!</p>}
        </div>
      </div>
    )
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen
      onRequestClose={closeModal}
      style={window.modal.large}
    >
      <CloseModalButton closeModal={closeModal} />

      {parcel.location && parcel.isAllocated !== false && !parcel.controller ? (
        <div>
          Kontakta{' '}
          <a href="mailto:robert.kofoed@qlocx.com?subject=Fel LOLOLOL vad är detta för produkt!?">
            robert.kofoed@qlocx.com
          </a>{' '}
          vid akuta problem
        </div>
      ) : (
        <>
          <Container>
            <Row>
              <Col {...window.bCol()}>
                <Barcode value={parcel.parcelId} />
              </Col>
            </Row>
          </Container>

          <br />

          <Container>
            {renderParcelDetails(transCommon)}

            <br />

            {parcel.currentStatus === 'created' &&
              (parcel.errors ||
                (parcel.parcelErrors && parcel.parcelErrors.length)) && (
                <div
                  style={{
                    backgroundColor: '#f1f1f1f1',
                    padding: 10,
                    borderRadius: 10,
                  }}
                >
                  <h4
                    style={{ cursor: 'pointer' }}
                    onClick={() => setErrorsIsExpanded(!errorsIsExpanded)}
                  >
                    ⚠️ {transCommon('error')}
                    <span style={{ fontWeight: 'bold' }}>
                      {errorsIsExpanded ? '' : '  >'}
                    </span>
                  </h4>
                  {errorsIsExpanded &&
                    [
                      ...(parcel.errors || []),
                      ...(parcel.parcelErrors || []),
                    ].map((error) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={JSON.stringify(error)}>{`${window.niceDate(
                        error.created
                      )} - ${t(`errors.${error.name}`)}${
                        error.status ? ` - Status ${error.status}` : ''
                      }`}</div>
                    ))}
                </div>
              )}

            {user.isAllowedTo('requestLargerCompartment') &&
              parcel.actions &&
              parcel.actions.canChangeBoxSize &&
              Array.isArray(boxTypes) &&
              boxTypes.length && (
                <>
                  <br />
                  <div
                    style={{
                      backgroundColor: '#f1f1f1f1',
                      padding: 10,
                      borderRadius: 10,
                    }}
                  >
                    <Row>
                      <Col {...window.bCol()}>
                        <h4 style={{ textAlign: 'center' }}>
                          {t('changeBoxSize')}
                        </h4>
                        <div
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {boxTypes.map((boxType) => (
                            <BoxOption
                              boxType={boxType}
                              parcel={parcel}
                              available={
                                !!availableBoxTypes?.find?.(
                                  (bt) =>
                                    bt?.display.friendlyName.default ===
                                    boxType?.display.friendlyName.default
                                )
                              }
                              key={boxType?.display?.illustration}
                            />
                          ))}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}

            <br />

            {['created', 'pickup-created'].includes(parcel.currentStatus) && (
              <div
                style={{
                  backgroundColor: '#f1f1f1f1',
                  padding: 10,
                  borderRadius: 10,
                }}
              >
                <Row>
                  <Col {...window.bCol()}>
                    <h4 style={{ textAlign: 'center' }}>{t('tryBookBox')}</h4>

                    <Col {...window.bCol()}>
                      <div
                        className="ibButton iEnquiry"
                        onClick={() =>
                          saveAddStatus(
                            parcel.currentStatus === 'created'
                              ? 'on-way'
                              : 'pickup-on-way'
                          )
                        }
                      >
                        {t('bookBox')}
                      </div>
                    </Col>
                  </Col>
                </Row>
              </div>
            )}

            <br />

            <div
              style={{
                backgroundColor: '#f1f1f1f1',
                padding: 10,
                borderRadius: 10,
                width: '100%',
                height: '100%',
              }}
            >
              <Row>
                <Col {...window.bCol()}>
                  <h4 style={{ textAlign: 'center' }}>{t('statusesStr')}</h4>
                  <table
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      overflow: 'auto',
                      maxHeight: '500px',
                      maxWidth: '800px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                  >
                    <tbody>
                      <tr style={{ display: 'flex' }}>
                        <th
                          style={{
                            backgroundColor: '#FFFFFF',
                            padding: '4px 10px',
                            outline: 'rgb(230, 230, 230) solid 1px',
                            flex: 1,
                            minWidth: 0,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            maxWidth: '180px',
                            overflow: 'hidden',
                          }}
                        >
                          {t('timeCreated')}
                        </th>
                        <th
                          style={{
                            backgroundColor: '#FFFFFF',
                            padding: '4px 10px',
                            outline: 'rgb(230, 230, 230) solid 1px',
                            flex: 2,
                            minWidth: 0,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {t('status')}
                        </th>
                        <th
                          style={{
                            backgroundColor: '#FFFFFF',
                            padding: '4px 10px',
                            outline: 'rgb(230, 230, 230) solid 1px',
                            flex: 1,
                            minWidth: 0,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          System
                        </th>
                        <th
                          style={{
                            backgroundColor: '#FFFFFF',
                            padding: '4px 10px',
                            outline: 'rgb(230, 230, 230) solid 1px',
                            flex: 1,
                            minWidth: 0,
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {t('user')}
                        </th>
                      </tr>
                      {(parcel.statuses || []).map((sts) => {
                        return (
                          <tr
                            key={JSON.stringify(sts)}
                            style={{ display: 'flex' }}
                          >
                            <td
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                textAlign: 'center',
                                fontSize: '14px',
                                flex: 1,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '180px',
                                overflow: 'hidden',
                              }}
                            >
                              {window.niceDate2(sts.created)}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 2,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textAlign: 'start',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {t(`statuses.${sts.name}`)}
                              {sts.data && sts.data.code
                                ? ` - ${sts.data.code}`
                                : null}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 1,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textAlign: 'start',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {statusTransitionServiceTranslation(sts.service)}
                            </td>
                            <td
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 1,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textAlign: 'start',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {!sts.service?.match(/^retool/)
                                ? sts.serviceUser
                                : ''}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
              {user.isAllowedTo('parcelAddStatus') && (
                <Row className="mt-4">
                  <div style={{ maxWidth: '800px', margin: '0 auto', flex: 1 }}>
                    <Col {...window.bCol()}>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <div style={{ width: '150px' }}>{t('addStatus')}</div>
                        <div style={{ width: '80%' }}>
                          <select
                            style={{ width: '80%' }}
                            onChange={(e) =>
                              setAddStatus({
                                ...addStatus,
                                name: e.target.value,
                              })
                            }
                          >
                            {appendableStatuses.map((o) => (
                              <option key={o} value={o}>
                                {t(`statuses.${o}`)}
                                {o.data && o.data.code ? o.date.code : null}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Specialhaxx för att bring vill kunna skjuta med extra data på just denna status, låter den vara ett hack tills vidare */}
                        {['marked-for-return'].includes(addStatus.name) &&
                          carrier.username === 'bring' && (
                            <div>
                              <select
                                onChange={(e) =>
                                  e.target.value.includes('ReasonCode') &&
                                  setAddStatus({
                                    ...addStatus,
                                    data: { extra: e.target.value },
                                  })
                                }
                              >
                                <option disabled={addStatus.data}>
                                  {t('pickCodes')}
                                </option>
                                {bringExtraCodes.map((e) => (
                                  <option
                                    key={JSON.stringify(e)}
                                    value={JSON.stringify(e)}
                                  >
                                    {e.description}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}

                        <div style={{ width: '200px', textAlign: 'left' }}>
                          <div
                            className="ibButton iEnquiry"
                            onClick={saveAddStatus}
                          >
                            {t('add')}
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                </Row>
              )}
            </div>
            {(parcel.auditTrail?.length ?? 0) > 0 && (
              <>
                <br />

                <div
                  style={{
                    backgroundColor: '#f1f1f1f1',
                    padding: 10,
                    borderRadius: 10,
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Row>
                    <Col {...window.bCol()}>
                      <h4 style={{ textAlign: 'center' }}>{t('userLogs')}</h4>
                      <table
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          overflow: 'auto',
                          maxHeight: '500px',
                          maxWidth: '800px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                      >
                        <tbody>
                          <tr style={{ display: 'flex' }}>
                            <th
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px 10px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 1,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '180px',
                                overflow: 'hidden',
                              }}
                            >
                              {t('time')}
                            </th>
                            <th
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px 10px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 2,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {t('log')}
                            </th>
                            <th
                              style={{
                                backgroundColor: '#FFFFFF',
                                padding: '4px 10px',
                                outline: 'rgb(230, 230, 230) solid 1px',
                                flex: 1,
                                minWidth: 0,
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                            >
                              {t('user')}
                            </th>
                          </tr>
                          {(parcel.auditTrail || [])
                            .filter(
                              (auditTrail) =>
                                auditTrail?.serviceCallerChain?.includes(
                                  'iadmin'
                                ) &&
                                !(
                                  auditTrail?.type === 'CHANGE' &&
                                  auditTrail?.subType === 'STATUS'
                                )
                            )
                            .map((log) => {
                              return (
                                <tr
                                  key={JSON.stringify(log)}
                                  style={{ display: 'flex' }}
                                >
                                  <td
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      padding: '4px',
                                      outline: 'rgb(230, 230, 230) solid 1px',
                                      textAlign: 'center',
                                      fontSize: '14px',
                                      flex: 1,
                                      minWidth: 0,
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '180px',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {window.niceDate2(log.created)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      padding: '4px',
                                      outline: 'rgb(230, 230, 230) solid 1px',
                                      flex: 2,
                                      minWidth: 0,
                                      whiteSpace: 'nowrap',
                                      textAlign: 'start',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {transformLogString(log, t)}
                                  </td>
                                  <td
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      padding: '4px',
                                      outline: 'rgb(230, 230, 230) solid 1px',
                                      flex: 1,
                                      minWidth: 0,
                                      whiteSpace: 'nowrap',
                                      textAlign: 'start',
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {log.username}
                                  </td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <br />
            <br />
            <div
              style={{
                backgroundColor: '#f1f1f1f1',
                padding: 10,
                borderRadius: 10,
                width: '100%',
              }}
            >
              <h4 style={{ textAlign: 'center' }}>{t('driverLogs')}</h4>
              <table
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  maxHeight: '585px',
                  maxWidth: '800px',
                  margin: '0 auto',
                  width: '100%',
                }}
              >
                <tbody>
                  {parcelLogs.length > 0 && (
                    <tr style={{ display: 'flex' }}>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          minWidth: 0,
                          maxWidth: '180px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('timeCreated')}
                      </th>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 2,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('log')}
                      </th>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('driver')}
                      </th>
                    </tr>
                  )}
                  {parcelLogs.map((log) => (
                    <tr
                      // eslint-disable-next-line react/no-array-index-key
                      key={JSON.stringify(log)}
                      style={{ display: 'flex' }}
                    >
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          textAlign: 'center',
                          fontSize: '14px',
                          flex: 1,
                          minWidth: 0,
                          maxWidth: '180px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.friendlyDate}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 2,
                          minWidth: 0,
                          textAlign: 'start',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.log}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          textAlign: 'start',
                          fontSize: '14px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.driver}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <div
              style={{
                backgroundColor: '#f1f1f1f1',
                padding: 10,
                borderRadius: 10,
                width: '100%',
              }}
            >
              <h4 style={{ textAlign: 'center' }}>{t('otherLogs')}</h4>
              <table
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflow: 'auto',
                  maxHeight: '585px',
                  maxWidth: '800px',
                  margin: '0 auto',
                  width: '100%',
                }}
              >
                <tbody>
                  {serviceLogs.length > 0 && (
                    <tr style={{ display: 'flex' }}>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          minWidth: 0,
                          maxWidth: '180px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('timeCreated')}
                      </th>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 2,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('log')}
                      </th>
                      <th
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {t('user')}
                      </th>
                    </tr>
                  )}
                  {serviceLogs.map((log) => (
                    <tr
                      // eslint-disable-next-line react/no-array-index-key
                      key={JSON.stringify(log)}
                      style={{ display: 'flex' }}
                    >
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          textAlign: 'center',
                          fontSize: '14px',
                          flex: 1,
                          minWidth: 0,
                          maxWidth: '180px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.friendlyDate}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 2,
                          minWidth: 0,
                          textAlign: 'start',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.log}
                      </td>
                      <td
                        style={{
                          backgroundColor: '#FFFFFF',
                          padding: '4px 10px',
                          outline: '1px solid #e6e6e6',
                          flex: 1,
                          textAlign: 'start',
                          fontSize: '14px',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {log.logType === 'consumer' ? t('consumer') : 'SDK'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Container>
        </>
      )}
    </Modal>
  )
}
