import React, { useMemo } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import dayjs from 'dayjs'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import './lang/i18n'
import Login from './login'
import CarrierHome from './carrier/home'
import statuses from './utils/statuses'

import 'bootstrap/dist/css/bootstrap.min.css'
import './overrides.css'
import './leafletStyles.css'
import './flex.css'

console.log('hostnam', window.location.hostname)
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://24c527f6f25548749c0a219e9010b315@o456254.ingest.sentry.io/5653568',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: window.location.hostname.includes('staging')
      ? 'staging'
      : 'production',
  })
}
console.log('NODE_ENV', process.env.NODE_ENV)

/* eslint-disable-next-line */
console.log(
  process.env.NODE_ENV === 'development' && (process.env, process.env.NODE_ENV)
)

window.bCol = (size = 12) => ({ xs: size, sm: size, md: size, lg: size })

window.isLocal = window.location.hostname.includes('localhost')
window.environment = {
  localhost: 'local',
  'admin.iboxen-staging.se': 'staging',
  'admin.qlocxiboxen.com': 'production',
}[window.location.hostname]
/* eslint-disable-next-line */
console.log(
  process.env.NODE_ENV === 'development' && ('env', window.environment)
)
if (window.isLocal) {
  window.backendUrl = `http://localhost:3000/api`
} else {
  window.backendUrl = `${window.location.origin}/api`
}

window.boxSizes = {
  'iboxen small v1': [155, 580, 460],
  'iboxen medium v1': [332, 580, 460],
  'iboxen large v1': [509, 580, 460],
}

const generateModalSize = (reduce = 0.05) => {
  return {
    overlay: {
      zIndex: 100,
    },
    content: {
      top: `${reduce * 100}%`,
      left: `${reduce * 100}%`,
      right: `${reduce * 100}%`,
      bottom: `${reduce * 100}%`,
      borderRadius: '10px',
      textAlign: 'center',
      color: 'rgb(83, 88, 95)',
      boxShadow: '2px 2px 30px grey',
    },
  }
}
window.generateModalSize = generateModalSize

window.statusOptions = statuses

window.niceDate = (str) => dayjs(str).format('YYYY-MM-DD HH:mm')
window.niceDate2 = (str) => dayjs(str).format('YYYY-MM-DD HH:mm:ss')
window.smallDate = (str) => dayjs(str).format('HH:mm DD/MM')
window.systemDate = (str) => dayjs(str).format('DD/MM-YY')

window.modal = {
  large: generateModalSize(),
  medium: generateModalSize(0.7),
  small: generateModalSize(0.3),
}
const getHome = () => {
  return CarrierHome
}

function PrivateRoute({ component: Component, ...rest }) {
  let isAuthed = false
  if (localStorage.jwt && localStorage.jwt.length > 80) isAuthed = true

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthed ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default function App() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          iboxen: {
            main: '#005060',
            light: '#33737f',
            dark: '#003843',
            contrastText: '#fff',
          },
          background: {
            paper: '#f1f1f1',
          },
        },
        typography: {
          h1: { fontSize: '3rem' },
          h2: { fontSize: '2rem' },
          h3: { fontSize: '1.6rem' },
          h4: { fontSize: '1.4rem' },
          h5: { fontSize: '1.2rem' },
        },
      }),
    []
  )
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={Login} />

          <PrivateRoute path="/carrier" component={CarrierHome} />

          <PrivateRoute path="*" component={getHome()} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  )
}
